import {createHashRouter,} from "react-router-dom";
import Shop_Root_Layout from "./routes/nftc_root_layout";
import ErrorPage from "./error-page";
import ShopHomePage from "./components/home-page";
import ShopViewPage from "./components/view_page";
import ShopViewCart from "./components/view_cart";
import LPHomePage from "./components/lp-home-page";
import PrivacyPage from "./components/privacy-page";
import TermsPage from "./components/terms-page";
import NFTCRootLayout from "./routes/nftc_root_layout";
import PaymentComplete from "./components/payment_complete";

export const router = createHashRouter([
    {
        path: "/",
        element: <NFTCRootLayout/>,
        errorElement: <ErrorPage/>,
        children: [
            {
                index: true,
                element: <LPHomePage/>,
            },
            {
                path: 'privacy',
                element: <PrivacyPage/>
            },
            {
                path: 'terms',
                element: <TermsPage/>
            },
            {
                path: "shop",
                children: [
                    {
                        index: true,
                        element: <ShopHomePage/>,
                    },
                    {
                        path: "/shop/view/:collectionAddress/:tokenID",
                        element: <ShopViewPage/>,
                    },
                    {
                        path: "/shop/cart",
                        element: <ShopViewCart/>,
                    },
                    {
                        path: "/shop/payment_complete",
                        element: <PaymentComplete/>,
                    },
                ]
            }
        ],
    },
]);