import * as React from 'react';
import {NFTInfo} from "../types";
import {InfinitySpin} from "react-loader-spinner";


export default function NFTImg(item: NFTInfo) {
    const [isLoading, setIsLoading] = React.useState<boolean>(true);

    let imageUrl = item.normalized_metadata.image;
    if (imageUrl.startsWith("ipfs://")) {
        imageUrl = `${process.env.REACT_APP_TOOLS_API_HOST}/nfts/ipfs?${new URLSearchParams({ipfsUrl: imageUrl}).toString()}`
    }
    return <div style={{display: "flex", alignItems: "center"}}>
        <img src={imageUrl} alt={item.name} className={"img-responsive"} style={{display: isLoading ? "none" : "block"}}
             onLoad={event => {
                 setIsLoading(false);
             }}/>
        <div style={{display: isLoading ? "block" : "none", alignItems: "center", width: "100%"}}>
            <InfinitySpin
                width='200'
                color="#FFFFFF"
            />
        </div>
    </div>;
}