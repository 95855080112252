import logo_light from "../../img/logo-light.png";
import * as React from "react";
// @ts-ignore
import Navbar from "react-bootstrap/es/Navbar";
// @ts-ignore
import Nav from "react-bootstrap/es/Nav";
// @ts-ignore
import NavItem from "react-bootstrap/es/NavItem";
import {useAccount} from "wagmi";
import {ConnectButton} from "@rainbow-me/rainbowkit";
import {Link} from "react-router-dom";
import {FreeShippingBanner} from "../free_shipping_banner";
import {useCart} from "react-use-cart";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCartShopping} from "@fortawesome/free-solid-svg-icons";
import {NFTC_Connect_Button} from "./nftc_connect_button";

import { usePublicClient } from 'wagmi'


function NavBarWallet() {
    const { address, isConnected } = useAccount();
    const { totalUniqueItems } = useCart();

    return (
        <>
            <NavItem eventKey={7} >
                <div style={{position: "relative"}}>
                    <NFTC_Connect_Button />
                </div>
            </NavItem>
            <NavItem eventKey={8} href={'#/shop/cart'} style={{display: isConnected ? "block" : "none"}}>
                <span className="icon-cart">
                    <FontAwesomeIcon icon={faCartShopping} style={{color: "white"}}/>
                    <span className="count cart-count">{totalUniqueItems}</span>
                </span>
            </NavItem>
        </>
    );
}


function NavBarShopItem() {
    const { address, isConnected } = useAccount();

    if (isConnected) {
        return (
            <NavItem eventKey={1} className={"page-scroll link-underline-menu"} href={'#/shop'}>
                <Link to={'/shop'}>Shop</Link>
            </NavItem>
        );
    } else {
        return null;
    }
}

export function NFTCBootstrapNavbar() {
    const { address, isConnected } = useAccount({
        onConnect: ({ address, connector, isReconnected }) => {
            console.log('Connected', { address, connector, isReconnected })
            // @ts-ignore
            window.dataLayer = window.dataLayer || [];
            // @ts-ignore
            window.dataLayer.push({
                event: "wallet_connected",
                wallet_address: address
            });
        },
    });
    const { totalUniqueItems } = useCart();

    return <Navbar inverse fluid className={"navbar-bg-switch"} style={{backgroundColor: "#000"}} collapseOnSelect>
        <div className="main-navigation">
            {/*To show free shipping bar uncomment this but it messes with section scroll to shit.*/}
            {/*<FreeShippingBanner />*/}
            <Navbar.Header>
                <Link className="navbar-brand hidden-sm hidden-md hidden-lg logo page-scroll active" to="/" style={{
                    marginTop: "-3px",
                    marginLeft: "-30px",
                }}>
                    <img alt="Logo" className="logo-light" src={logo_light}/>
                </Link>
                <Navbar.Toggle style={{marginTop: "-48px"}}/>
                <Link className="navbar-brand hidden-sm hidden-md hidden-lg icon-cart" to="/shop/cart" style={{
                    float: "right",
                    marginTop: "8px",
                    display: isConnected ? "block" : "none"
                }}>
                    <FontAwesomeIcon icon={faCartShopping} style={{color: "white"}}/>
                    <span className="count cart-count">{totalUniqueItems}</span>
                </Link>
            </Navbar.Header>
            <Navbar.Collapse>
                <Nav>
                    {/*<NavBarShopItem />*/}
                    <NavItem eventKey={1} className={"page-scroll link-underline-menu"} href={'#/shop'} style={{display: isConnected ? "block" : "none"}}>
                        <Link to={'/shop'}>Shop</Link>
                    </NavItem>
                    <NavItem  eventKey={2} className={"page-scroll link-underline-menu"} href={'#/#about'}>
                        <Link to={'/#about'}>About</Link>
                    </NavItem>
                    <NavItem  eventKey={3} className={"page-scroll link-underline-menu"} href={'#/#services'}>
                        <Link to={'/#services'}>Services</Link>
                    </NavItem>
                    <NavItem eventKey={4}  className={"hidden-xs page-scroll link-underline-menu"} href={'#/'}>
                        <Link to={'/'}><img alt="Logo" className="logo-light" src={logo_light}/></Link>
                    </NavItem>
                    <NavItem eventKey={5}  className={"page-scroll link-underline-menu"} href={'#/#works'}>
                        <Link to={'/#works'}>Works</Link>
                    </NavItem>
                    <NavItem eventKey={6}  className={"page-scroll link-underline-menu"} href={'#/#contact'}>
                        <Link to={'/#contact'}>Contact</Link>
                    </NavItem>
                </Nav>
                <Nav className={"topbar-right"}>
                    <NavItem eventKey={7}>
                        <div style={{position: "relative"}}>
                            <NFTC_Connect_Button />
                        </div>
                    </NavItem>
                    <NavItem eventKey={8} href={'#/shop/cart'} style={{display: isConnected ? "block" : "none", marginTop: "5px"}}>
                        <Link to="/shop/cart">
                            <FontAwesomeIcon icon={faCartShopping} style={{color: "white"}}/>
                            <span className="count cart-count">{totalUniqueItems}</span>
                        </Link>
                    </NavItem>
                </Nav>
            </Navbar.Collapse>
        </div>
    </Navbar>;
}