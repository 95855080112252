import * as React from 'react';
import {useEffect, useState} from 'react';
import {useAccount} from "wagmi";
import {NFTCartInfo} from "../types";
import {Link, useNavigate} from "react-router-dom";
import {useCart} from "react-use-cart";
import {CoinbaseCommerceButton} from '@iofate/react-coinbase-commerce';
import '@iofate/react-coinbase-commerce/dist/esm/index.css';
import NFTImg from "./nftImg";
import {Helmet} from "react-helmet-async";
import {useDatalayerPushEvent} from "../hooks/analytics";


export default function ViewCart() {
    const navigate = useNavigate();
    const {address, isConnected} = useAccount();
    const {totalUniqueItems, cartTotal, items, removeItem} = useCart();
    const [couponCode, setCouponCode] = useState<string>('');
    const [couponApplied, setCouponApplied] = React.useState<boolean>(false);
    const [appliedCouponCode, setAppliedCouponCode] = React.useState<string>('');
    const [couponIsInvalid, setCouponIsInvalid] = React.useState<boolean>(false);
    const [couponAppliedPrice, setCouponAppliedPrice] = useState<number | null>(null);
    const [checkoutInProgress, setCheckoutInProgress] = useState(false);
    const [checkoutId, setCheckoutId] = useState(null);
    const [checkoutResponseData, setCheckoutResponseData] = useState(null);
    // These 3 are for the Coinbase Commerce button
    const [paymentDetected, setPaymentDetected] = useState<boolean | null>(null);
    const [chargeSuccess, setChargeSuccess] = useState<boolean | null>(null);
    const [chargeFailure, setChargeFailure] = useState<boolean | null>(null);
    const {pushPageView} = useDatalayerPushEvent();

    useEffect(() => {
        pushPageView();
        let viewCartData = {
            event: "view_cart",
            currency: "USD",
            value: cartTotal,
            // @ts-ignore
            items: items.map((x: NFTCartInfo) => {
                return {
                    item_id: `${x?.nftInfo.token_address}_${x?.nftInfo.token_id}`,
                    item_name: `${x?.nftInfo.name} #${x?.nftInfo.token_id}`,
                    item_brand: x?.nftInfo.name,
                    item_list_id: x?.nftInfo.token_address,
                    item_list_name: x?.nftInfo.name,
                    price: x?.price,
                    print_size: x?.selectedSize.displayName,
                    print_gloss: x?.selectedGloss.displayName,
                }
            })
        };
        // @ts-ignore
        window.dataLayer = window.dataLayer || [];
        // @ts-ignore
        window.dataLayer.push(viewCartData);
    }, []);

    function ApplyCoupon() {
        const checkCouponCall = async () => {
            let data = await fetch(`${process.env.REACT_APP_TOOLS_API_HOST}/checkout/check_coupon`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "couponCode": couponCode,
                    // @ts-ignore
                    "items": items.map((x: NFTCartInfo) => {
                        return {
                            "collectionAddress": x.nftInfo.token_address,
                            "tokenId": x.nftInfo.token_id,
                            "sizeDisplayName": x.selectedSize.displayName,
                            "glossDisplayName": x.selectedGloss.displayName,
                        }
                    })
                })
            });
            let jsonResponse = await data.json();
            if (jsonResponse.valid) {
                setAppliedCouponCode(couponCode);
                setCouponApplied(true);
                setCouponIsInvalid(false);
                setCouponAppliedPrice(jsonResponse.new_price);
                let applyCouponData = {
                    event: "apply_coupon",
                    coupon: couponCode,
                };
                // @ts-ignore
                window.dataLayer = window.dataLayer || [];
                // @ts-ignore
                window.dataLayer.push(applyCouponData);
            } else {
                setCouponCode('');
                setAppliedCouponCode('');
                setCouponApplied(false);
                setCouponIsInvalid(true);
            }
        };
        if (couponCode.length > 0) {
            checkCouponCall().catch(console.error);
        }
    }

    function beginCheckout() {
        setCheckoutInProgress(true);
        const createCheckout = async () => {
            // @ts-ignore
            let data = await fetch(`${process.env.REACT_APP_TOOLS_API_HOST}/checkout/create`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "walletAddress": address,
                    "couponCode": appliedCouponCode,
                    // @ts-ignore
                    "items": items.map((x: NFTCartInfo) => {
                        return {
                            "collectionAddress": x.nftInfo.token_address,
                            "tokenId": x.nftInfo.token_id,
                            "sizeDisplayName": x.selectedSize.displayName,
                            "glossDisplayName": x.selectedGloss.displayName,
                        }
                    })
                })
            });
            let jsonResponse = await data.json();
            setCheckoutId(jsonResponse.id);
            setCheckoutResponseData(jsonResponse);
            let beingCheckoutEventData = {
                event: "begin_checkout",
                currency: "USD",
                value: parseFloat(jsonResponse.local_price.amount),
                // @ts-ignore
                items: items.map((x: NFTCartInfo) => {
                    return {
                        item_id: `${x?.nftInfo.token_address}_${x?.nftInfo.token_id}`,
                        item_name: `${x?.nftInfo.name} #${x?.nftInfo.token_id}`,
                        item_brand: x?.nftInfo.name,
                        item_list_id: x?.nftInfo.token_address,
                        item_list_name: x?.nftInfo.name,
                        price: x?.price,
                        print_size: x?.selectedSize.displayName,
                        print_gloss: x?.selectedGloss.displayName,
                    }
                })
            };
            if (appliedCouponCode.length > 0){
                // @ts-ignore
                beingCheckoutEventData.coupon = appliedCouponCode;
            }
            // @ts-ignore
            window.dataLayer = window.dataLayer || [];
            // @ts-ignore
            window.dataLayer.push(beingCheckoutEventData);
        }
        createCheckout().catch(console.error);
    }

    function reportPaymentCallback(cb_name: string) {
        let paymentCallbackEventData = {
            event: cb_name,
            currency: "USD",
            // @ts-ignore
            value: parseFloat(checkoutResponseData!.local_price.amount),
            // @ts-ignore
            items: items.map((x: NFTCartInfo) => {
                return {
                    item_id: `${x?.nftInfo.token_address}_${x?.nftInfo.token_id}`,
                    item_name: `${x?.nftInfo.name} #${x?.nftInfo.token_id}`,
                    item_brand: x?.nftInfo.name,
                    item_list_id: x?.nftInfo.token_address,
                    item_list_name: x?.nftInfo.name,
                    price: x?.price,
                    print_size: x?.selectedSize.displayName,
                    print_gloss: x?.selectedGloss.displayName,
                }
            })
        };
        if (appliedCouponCode.length > 0){
            // @ts-ignore
            paymentCallbackEventData.coupon = appliedCouponCode;
        }
        // @ts-ignore
        window.dataLayer = window.dataLayer || [];
        // @ts-ignore
        window.dataLayer.push(paymentCallbackEventData);

        const markPaymentCompleteAPI = async () => {
            if (cb_name === "nftc_payment_success") {
                let data = await fetch(`${process.env.REACT_APP_TOOLS_API_HOST}/checkout/mark_payment_complete`, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        "orderId": checkoutId,
                    })
                });
            }
        }
        markPaymentCompleteAPI().catch(console.error);
    }

    function NFTCheckoutButton() {
        if (checkoutId === null) {
            return <button
                className="btn link-button hover-white checkout"
                onClick={beginCheckout}
                disabled={checkoutInProgress}>{checkoutInProgress ? "Please Wait..." : "Begin Checkout"}</button>
        } else {
            if (paymentDetected === null && chargeFailure === null && chargeSuccess === null) {
                return <CoinbaseCommerceButton checkoutId={checkoutId} className="btn link-button hover-white checkout"
                                               onChargeFailure={data => {
                                                   console.debug('onChargeFailure');
                                                   console.error(data);
                                                   setChargeFailure(true);
                                                   setChargeSuccess(false);
                                                   reportPaymentCallback('charge_failure')
                                               }}
                                               onChargeSuccess={data => {
                                                   console.debug('onChargeSuccess');
                                                   console.debug(data);
                                                   setChargeFailure(false);
                                                   setChargeSuccess(true);
                                                   reportPaymentCallback('charge_success')
                                                   reportPaymentCallback('nftc_payment_success')
                                                   navigate('/shop/payment_complete');
                                               }}
                                               onPaymentDetected={data => {
                                                   console.debug('onPaymentDetected');
                                                   setPaymentDetected(true);
                                                   reportPaymentCallback('payment_detected')
                                                   reportPaymentCallback('nftc_payment_success')
                                                   navigate('/shop/payment_complete');
                                               }}
                                               onLoad={() => {
                                                   let beingCoinbaseCheckoutEventData = {
                                                       event: "begin_coinbase_checkout",
                                                       currency: "USD",
                                                       // @ts-ignore
                                                       value: parseFloat(checkoutResponseData!.local_price.amount),
                                                       // @ts-ignore
                                                       items: items.map((x: NFTCartInfo) => {
                                                           return {
                                                               item_id: `${x?.nftInfo.token_address}_${x?.nftInfo.token_id}`,
                                                               item_name: `${x?.nftInfo.name} #${x?.nftInfo.token_id}`,
                                                               item_brand: x?.nftInfo.name,
                                                               item_list_id: x?.nftInfo.token_address,
                                                               item_list_name: x?.nftInfo.name,
                                                               price: x?.price,
                                                               print_size: x?.selectedSize.displayName,
                                                               print_gloss: x?.selectedGloss.displayName,
                                                           }
                                                       })
                                                   };
                                                   if (appliedCouponCode.length > 0){
                                                       // @ts-ignore
                                                       beingCoinbaseCheckoutEventData.coupon = appliedCouponCode;
                                                   }
                                                   // @ts-ignore
                                                   window.dataLayer = window.dataLayer || [];
                                                   // @ts-ignore
                                                   window.dataLayer.push(beingCoinbaseCheckoutEventData);
                                               }}
                >Complete Purchase With Coinbase</CoinbaseCommerceButton>
            } else {
                if (paymentDetected === true && chargeFailure === null && chargeSuccess === null) {
                    return <button className="btn link-button hover-white checkout" disabled={true}>Your payment was
                        received!</button>
                } else if (chargeFailure === true && chargeSuccess === false) {
                    return <button className="btn link-button hover-white checkout" disabled={true}>There was a problem
                        with your payment.</button>
                } else if (chargeFailure === false && chargeSuccess === true) {
                    return <button className="btn link-button hover-white checkout" disabled={true}>Your payment was
                        received!</button>
                } else {
                    return <>The site has encountered a problem during checkout.</>
                }
            }
        }
    }

    function CartRow(item: NFTCartInfo) {
        return (<tr className="item_cart">
            <td className="product-photo"><NFTImg {...item.nftInfo} /></td>
            <td className="product-name" style={{paddingLeft: "10px"}}>
                Name: {item.name}
                <br/>
                Size: {item.selectedSize.displayName}
                <br/>
                Gloss Finish: {item.selectedGloss.displayName}
            </td>
            <td className="total-price">
                <button className="remove" onClick={(x) => {
                    removeItem(item.id)
                    let removeFromCartData = {
                        event: "remove_from_cart",
                        currency: "USD",
                        value: item.price,
                        items: [{
                            item_id: `${item?.nftInfo.token_address}_${item?.nftInfo.token_id}`,
                            item_name: `${item?.nftInfo.name} #${item?.nftInfo.token_id}`,
                            item_brand: item?.nftInfo.name,
                            price: item?.price,
                            print_size: item.selectedSize.displayName,
                            print_gloss: item.selectedGloss.displayName,
                        }]
                    };
                    // @ts-ignore
                    window.dataLayer = window.dataLayer || [];
                    // @ts-ignore
                    window.dataLayer.push(removeFromCartData);
                }} title="Remove Item" style={{backgroundColor: "black"}}>Remove
                </button>
                <p className="price">${item.price}</p>
            </td>
        </tr>);
    }

    if (!isConnected) {
        return (
            <>
                <Helmet>
                    <title>NFTCanvases.com | Cart Not Connected</title>
                </Helmet>
                <div className="inner-divider-large"></div>
                <div className="text-center">You must connect a wallet to utilize our website.</div>
            </>
        );
    } else {
        if (totalUniqueItems === 0) {
            return (
                <>
                    <Helmet>
                        <title>NFTCanvases.com | Empty Cart</title>
                    </Helmet>
                    <div className="container container-42">
                        &nbsp;
                    </div>
                    <div className="container" style={{marginTop: "5%"}}>
                        <div className="main-content text-center empty-cart-content space-padding-tb-60">
                            <div className="container">
                                <span className="close-empty-cart"></span>
                                <h3>Your cart is currently empty.</h3>
                                <Link to={"/shop"} className="bordersolid-2 btn-return">Return To Shop <span
                                    className="icon-arr"></span></Link>
                            </div>
                        </div>
                    </div>
                </>);
        } else {
            return (
                <>
                    <Helmet>
                        <title>NFTCanvases.com | Viewing Cart</title>
                    </Helmet>
                    <div className="container container-42">
                        &nbsp;
                    </div>
                    <div className="container" style={{marginTop: "5%"}}>
                        <div className="main-content">
                            <div className="cart-box-container-ver2">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-8">
                                            <h3>Shopping Cart</h3>
                                            <table className="table cart-table space-30">
                                                <tbody>
                                                {/*//@ts-ignore*/}
                                                {items.map((x: NFTCartInfo) => {
                                                    return <CartRow key={x.id} {...x} />
                                                })}
                                                </tbody>
                                            </table>
                                            <div className="row">
                                                <div className="col-xs-12 col-sm-6">
                                                    <Link to={"/shop"} className="btn link-button hover-black continue">Continue
                                                        Shopping</Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <h3>Coupon</h3>
                                            <div className="contact-form coupon">
                                                <form className="">
                                                    <div className="form-group">
                                                        <input key="couponCode" type="text"
                                                               className="form-control input-cart" id="couponCode"
                                                               value={couponCode}
                                                               placeholder="Coupon Code"
                                                               disabled={couponApplied}
                                                               onChange={event => {
                                                                   setCouponCode(event.target.value);
                                                                   setCouponIsInvalid(false);
                                                                   setCouponApplied(false);
                                                                   setAppliedCouponCode('');
                                                               }}
                                                        />
                                                        <button
                                                            className="btn link-button hover-white checkout"
                                                            onClick={ApplyCoupon}
                                                            disabled={couponApplied || couponCode.length === 0}>{couponApplied ? "Coupon Applied" : "Apply Coupon"}</button>
                                                    </div>
                                                    {couponIsInvalid ? <span className={"redtext"}>This coupon is invalid.</span> : <></>}
                                                </form>
                                            </div>
                                            <h3>Cart Totals</h3>
                                            <div className="text-price">
                                                <ul>
                                                    <li>
                                                        <span className="text" style={{textDecoration: couponAppliedPrice !== null ? "line-through" : "unset"}}>Total</span>
                                                        <span className="number" style={{textDecoration: couponAppliedPrice !== null ? "line-through" : "unset"}}>${cartTotal}</span>
                                                    </li>
                                                    { couponAppliedPrice !== null ? <li>
                                                        <span className="text">Total With Coupon</span>
                                                        <span className="number">${couponAppliedPrice}</span>
                                                    </li>
                                                    : <></> }
                                                </ul>
                                            </div>
                                            <div className="text-price">
                                                <NFTCheckoutButton/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>);
        }
    }
}