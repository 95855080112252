import * as React from 'react';
import "../css/plugins.css";
import "../css/style.css";

import logo_light from "../img/logo-light.png";
import big_place from "../img/services/1920x1280place.png";
import works_img_1 from "../img/works/works-large/works-img-1.jpg";
import works_img_2 from "../img/works/works-large/works-img-2.jpg";
import works_img_3 from "../img/works/works-large/works-img-3.jpg";
import works_img_4 from "../img/works/works-large/works-img-4.jpg";
import works_img_5 from "../img/works/works-large/works-img-5.jpg";
import works_img_6 from "../img/works/works-large/works-img-6.jpg";
import works_img_7 from "../img/works/works-large/works-img-7.jpg";
import works_img_8 from "../img/works/works-large/works-img-8.jpg";

import carousel_1 from "../img/background/thumbnail-slider-bg-1.jpg";
import carousel_2 from "../img/background/thumbnail-slider-bg-2.jpg";
import carousel_3 from "../img/background/thumbnail-slider-bg-3.jpg";
import carousel_4 from "../img/background/thumbnail-slider-bg-4.jpg";
import carousel_5 from "../img/background/thumbnail-slider-bg-5.jpg";

import {Link} from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import {Carousel} from "react-responsive-carousel";
import {Helmet} from "react-helmet-async";


export default function PrivacyPage() {

    return (
        <>
            <Helmet>
                <title>NFTCanvases.com | Privacy</title>
            </Helmet>
            <div style={{marginTop: "100px"}}>
                Under Construction.
            </div>
        </>
    );
}