import { ConnectButton } from '@rainbow-me/rainbowkit';
// @ts-ignore
import Button from "react-bootstrap/es/Button";

export const NFTC_Connect_Button = () => {
    return (
        <ConnectButton.Custom>
            {({
                  account,
                  chain,
                  openAccountModal,
                  openChainModal,
                  openConnectModal,
                  authenticationStatus,
                  mounted,
              }) => {
                // Note: If your app doesn't use authentication, you
                // can remove all 'authenticationStatus' checks
                const ready = mounted && authenticationStatus !== 'loading';
                const connected =
                    ready &&
                    account &&
                    chain &&
                    (!authenticationStatus ||
                        authenticationStatus === 'authenticated');
                return (
                    <div
                        {...(!ready && {
                            'aria-hidden': true,
                            'style': {
                                opacity: 0,
                                pointerEvents: 'none',
                                userSelect: 'none',
                            },
                        })}
                    >
                        {(() => {
                            if (!connected) {
                                return (
                                    // <button onClick={openConnectModal} type="button">
                                    <Button bsStyle="primary" bsSize="large" className={"custom-connect-btn"} onClick={openConnectModal}>
                                        Connect Wallet
                                    </Button>
                                    // </button>
                                );
                            }
                            if (chain.unsupported) {
                                return (
                                    <Button bsStyle="primary" bsSize="large" className={"custom-connect-btn"}  onClick={openChainModal}>
                                        Wrong network
                                    </Button>
                                );
                            }
                            return (
                                <div style={{ display: 'flex', gap: 12 }}>
                                    <Button bsStyle="primary" bsSize="large" className={"custom-connect-btn"}  onClick={openAccountModal}>
                                        {account.displayName}
                                        {account.displayBalance
                                            ? ` (${account.displayBalance})`
                                            : ''}
                                    </Button>
                                </div>
                            );
                        })()}
                    </div>
                );
            }}
        </ConnectButton.Custom>
    );
};
