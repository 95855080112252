import * as React from "react";
import newhero16 from "../img/newhero16.png";
import { ConnectButton } from '@rainbow-me/rainbowkit';
import {CartAea} from "./cart_area";
import {FreeShippingBanner} from "./free_shipping_banner";
import {TopBar} from "./top_bar";



export function MainHeroImage() {
    return (
        <div className="page-heading" style={{borderTop: "2px solid #eee", borderBottom: "2px solid #000"}}>
            <div className="banner-heading">
                <img src={newhero16} alt="" className="img-reponsive" />
                <div className="heading-content v3 text-center" />
            </div>
        </div>
    );
}