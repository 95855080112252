import * as React from 'react';
import "../css/plugins.css";
import "../css/style.css";
import big_place from "../img/services/1920x1280place.png";
import works_img_1 from "../img/works/works-large/works-img-1.jpg";
import works_img_2 from "../img/works/works-large/works-img-2.jpg";
import works_img_3 from "../img/works/works-large/works-img-3.jpg";
import works_img_4 from "../img/works/works-large/works-img-4.jpg";
import works_img_5 from "../img/works/works-large/works-img-5.jpg";
import works_img_6 from "../img/works/works-large/works-img-6.jpg";
import works_img_7 from "../img/works/works-large/works-img-7.jpg";
import works_img_8 from "../img/works/works-large/works-img-8.jpg";

import carousel_1 from "../img/background/thumbnail-slider-bg-1.jpg";
import carousel_2 from "../img/background/thumbnail-slider-bg-2.jpg";
import carousel_3 from "../img/background/thumbnail-slider-bg-3.jpg";
import carousel_4 from "../img/background/thumbnail-slider-bg-4.jpg";
import carousel_5 from "../img/background/thumbnail-slider-bg-5.jpg";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import {Carousel} from "react-responsive-carousel";
import logo_light from "../img/logo-light.png";
import {Link} from "react-router-dom";
import ContactSection from "./contact-section";
// @ts-ignore
import ModalImage from "react-modal-image";
import {Helmet} from "react-helmet-async";
import {useEffect} from "react";
import {useDatalayerPushEvent} from "../hooks/analytics";


function CarouselOverlay() {
    return <div className={"legend"}>
        <div className="introduction">
            <h1 className="home-page-title" style={{fontSize: "52px"}}>
                Onchain <span> authentic </span> nft prints
            </h1>
        </div>
    </div>;
}


function ImageSwiper() {
    return <Carousel showThumbs={true} autoPlay={true} dynamicHeight={true} thumbWidth={200}>
        <div><img src={carousel_1} /><CarouselOverlay /></div>
        <div><img src={carousel_2} /><CarouselOverlay /></div>
        <div><img src={carousel_3} /><CarouselOverlay /></div>
        <div><img src={carousel_4} /><CarouselOverlay /></div>
        <div><img src={carousel_5} /><CarouselOverlay /></div>
    </Carousel>;
}

function AboutSection() {
    return <section id="about">

        <div className="inner-divider-large"></div>

        <div className="container sections">

            <div className="dots">
                <div className="the-dots"></div>
            </div>
            <div className="dots-reverse">
                <div className="the-dots"></div>
            </div>

            <div className="row">

                <div className="col-lg-12">

                    <h2 className="section-heading section-heading-all section-heading-light">
                        <span>1.</span> About
                    </h2>

                    <div className="inner-divider-large"></div>

                    <div className="testimonials-slideshow">

                        <div className="testimonials-quote">

                            <div className="testimonials-quote-img">
                                <span className="ion-quote"></span>
                            </div>

                            <div className="inner-divider"></div>

                            <div className="section-intro">
                                <p>
                                    Nobody comes even close to creating this unboxing experience by @CanvasesNft
                                    and @sainthenrihouse. Pics and videos do no justice for how incredible this
                                    actually looks in person.
                                </p>
                            </div>

                            <div className="inner-divider"></div>
                            <div className="testimonials-signature">
                                @boredhefner, Azuki Spirit DAO
                            </div>
                        </div>

                        <div className="testimonials-quote">

                            <div className="testimonials-quote-img">
                                <span className="ion-quote"></span>
                            </div>

                            <div className="inner-divider"></div>

                            <div className="section-intro">
                                <p>
                                    Finally got a chance to open my amazing gift from @CanvasesNft I have to say
                                    it’s crazy good quality! Wow. And I’ve been around a lot of prints. Highly
                                    recommend!
                                </p>
                            </div>

                            <div className="inner-divider"></div>
                            <div className="testimonials-signature">
                                @starlordyftw, CEO 0n1Force
                            </div>
                        </div>

                        <div className="testimonials-quote">

                            <div className="testimonials-quote-img">
                                <span className="ion-quote"></span>
                            </div>

                            <div className="inner-divider"></div>

                            <div className="section-intro">
                                <p>
                                    @CanvasesNft are in a league of their own when it comes to getting your
                                    favorite NTFs turned into IRL art.
                                </p>
                            </div>

                            <div className="inner-divider"></div>
                            <div className="testimonials-signature">
                                @brushdawg, OnChain Monkey Celestial
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    </section>;
}

function ServicesSection() {
    return <section id="services">

        <div className="inner-divider-large"></div>

        <div className="container sections">

            <div className="dots">
                <div className="the-dots"></div>
            </div>
            <div className="dots-reverse">
                <div className="the-dots"></div>
            </div>

            <div className="row">

                <div className="col-lg-12">

                    <h2 className="section-heading section-heading-all section-heading-light">
                        <span>2.</span> Services
                    </h2>

                    <div className="inner-divider-large"></div>

                    <h2 className="section-subtitle">
                        <span>Collectors | Creators</span>
                    </h2>

                    <div className="inner-divider"></div>

                    <div className="section-intro">
                        <p>
                            We offer packages for brand new collectors all the way up to museum-quality, bespoke
                            experiences done in collaboration with <a href="https://twitter.com/sainthenrihouse"
                                                                      target="blank">@sainthenrihouse</a> where
                            we work with the client to produce a hand-crafted 1/1.
                        </p>
                    </div>

                    <div className="inner-divider"></div>
                </div>

                <div className="col-lg-12">

                    <div className="the-line"></div>
                </div>
            </div>

            <div className="inner-divider"></div>
        </div>

        <div className="container-fluid">

            <div className="row">

                <div className="col-md-6 nopadding">
                    <div className="img-fullwidth-wrapper">

                        <div className="triangle">
                            <div className="triangle-left"></div>
                        </div>

                        <div className="img-fullwidth img-fullwidth-all img-fullwidth-services-1"
                             style={{backgroundImage: `url(${big_place})`}}></div>
                    </div>
                </div>

                <div className="col-md-6 nopadding">
                    <div className="img-fullwidth-wrapper">
                        <div className="img-fullwidth img-fullwidth-bg-dark">

                            <div className="center-container">

                                <div className="center-block">

                                    <div className="post-all">
                                        <h4 className="post-all-heading post-all-heading-services">
                                            Collectors
                                        </h4>
                                        <div className="inner-divider"></div>

                                        <div className="section-txt-services">
                                            <p>
                                                We offer everything from standard canvas prints, to gallery
                                                wraps, to gold or silver leafing, to diamond dusted prints. And
                                                we're only getting started. <Link className=
                                                                                   "link-underline-light"
                                                                               to="/#contact">Let's create.</Link>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">

                <div className="col-md-6 col-md-push-6 nopadding">
                    <div className="img-fullwidth-wrapper">

                        <div className="triangle-reverse">
                            <div className="triangle-right"></div>
                        </div>

                        <div className="img-fullwidth img-fullwidth-all img-fullwidth-services-2"
                             style={{backgroundImage: `url(${big_place})`}}></div>
                    </div>
                </div>

                <div className="col-md-6 col-md-pull-6 nopadding">
                    <div className="img-fullwidth-wrapper">
                        <div className="img-fullwidth img-fullwidth-bg-dark">

                            <div className="center-container">

                                <div className="center-block">

                                    <div className="post-all">
                                        <h4 className="post-all-heading post-all-heading-services">
                                            Creators
                                        </h4>
                                        <div className="inner-divider"></div>

                                        <div className="section-txt-services">
                                            <p>
                                                For collections looking to add value, reward holders, or make
                                                your event stand out, our results speak for themselves. <Link
                                                className=
                                                    "link-underline-light" to="/#contact">Contact us.</Link>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>;
}

function WorksSection() {
    return <section id="works">

        <div className="inner-divider-large"></div>

        <div className="container sections">

            <div className="dots">
                <div className="the-dots"></div>
            </div>
            <div className="dots-reverse">
                <div className="the-dots"></div>
            </div>

            <div className="row">

                <div className="col-lg-12">

                    <h2 className="section-heading section-heading-all section-heading-light">
                        <span>3.</span> Works
                    </h2>

                </div>

                <div className="col-lg-12">

                </div>
            </div>
        </div>
    </section>;
}

function FactsSection() {
    return <section className="section-parallax" id="facts">

        <div className="container-fluid sections">

            <div className="row">

                <div className="parallax parallax-facts" data-parallax-speed="3.0"
                     style={{backgroundImage: "none"}}>

                    <div className="parallax-overlay"></div>

                    <div className="parallax-content">

                        <div className="facts-counter-wrapper">

                            <div className="col-xs-6 col-sm-3">

                                <div className="facts-counter-number">
                                    8
                                </div>
                                <div className="facts-counter-description facts-counter-description-primary">

                                    <div className="inner-divider"></div>

                                    <div className="the-line the-line-facts"></div>

                                    <div className="inner-divider"></div>
                                    <span
                                        className="facts-counter-title">Months Since We<br/>Shipped Our First Print</span>
                                </div>
                            </div>

                            <div className="col-xs-6 col-sm-3">

                                <div className="facts-counter-number">
                                    71
                                </div>
                                <div className="facts-counter-description facts-counter-description-primary">

                                    <div className="inner-divider"></div>

                                    <div className="the-line the-line-facts"></div>

                                    <div className="inner-divider"></div>
                                    <span
                                        className="facts-counter-title">% Of Customers to<br/>Place a Second Order</span>
                                </div>
                            </div>

                            <div className="clearfix visible-xs-block"></div>

                            <div className="col-xs-6 col-sm-3">

                                <div className="facts-counter-number">
                                    84
                                </div>
                                <div className="facts-counter-description">

                                    <div className="inner-divider"></div>

                                    <div className="the-line the-line-facts"></div>

                                    <div className="inner-divider"></div>
                                    <span className="facts-counter-title">% Still Holding NFT<br/>After Using Our Service</span>
                                </div>
                            </div>

                            <div className="col-xs-6 col-sm-3">

                                <div className="facts-counter-number">
                                    16451
                                </div>
                                <div className="facts-counter-description">

                                    <div className="inner-divider"></div>

                                    <div className="the-line the-line-facts"></div>

                                    <div className="inner-divider"></div>
                                    <span
                                        className="facts-counter-title">Avg NFT Value in USD<br/>(At time of print)</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>;
}

function WorksImages() {
    return <div id="works-img-wrapper">
        <div className="image-grid">

            <div className="item-folio item item-wh">
                <div className="item-grid-size">

                    <div className="works-img-all fill">
                        <ModalImage
                            small={works_img_1}
                            large={works_img_1}
                            alt="OCM Karma #30003"
                            hideDownload={true}
                        />
                    </div>

                    <div className="description">

                        <h3>
                            OCM Karma #30003
                        </h3>

                        <div className="inner-divider-half inner-divider-half-works"></div>

                        <div className="description-second">
                            @brushdawg
                        </div>

                        <div className="inner-divider-half inner-divider-half-works"></div>
                    </div>
                </div>
            </div>

            <div className="item-folio item item-wh">
                <div className="item-grid-size">

                    <div className="works-img-all fill">
                        <ModalImage
                            small={works_img_2}
                            large={works_img_2}
                            alt="Branded Packaging x Saint Henri"
                            hideDownload={true}
                        />
                    </div>

                    <div className="description">

                        <h3>
                            Branded Packaging
                        </h3>

                        <div className="inner-divider-half inner-divider-half-works"></div>

                        <div className="description-second">
                            x Saint Henri
                        </div>

                        <div className="inner-divider-half inner-divider-half-works"></div>
                    </div>
                </div>
            </div>

            <div className="item-folio item item-wh">
                <div className="item-grid-size">

                    <div className="works-img-all fill">
                        <ModalImage
                            small={works_img_3}
                            large={works_img_3}
                            alt="Double Vision 0010"
                            hideDownload={true}
                        />
                    </div>


                    <div className="description">

                        <h3>
                            Double Vision 0010
                        </h3>

                        <div className="inner-divider-half inner-divider-half-works"></div>

                        <div className="description-second">
                            @ericpauseart
                        </div>

                        <div className="inner-divider-half inner-divider-half-works"></div>
                    </div>
                </div>
            </div>

            <div className="item-folio item item-wh">
                <div className="item-grid-size">

                    <div className="works-img-all fill">
                        <ModalImage
                            small={works_img_4}
                            large={works_img_4}
                            alt="Azuki #1234"
                            hideDownload={true}
                        />
                    </div>

                    <div className="description">

                        <h3>
                            Azuki #1234
                        </h3>

                        <div className="inner-divider-half inner-divider-half-works"></div>

                        <div className="description-second">
                            @sebbsssss
                        </div>

                        <div className="inner-divider-half inner-divider-half-works"></div>
                    </div>
                </div>
            </div>

            <div className="item-folio item item-wh">
                <div className="item-grid-size">

                    <div className="works-img-all fill">
                        <ModalImage
                            small={works_img_5}
                            large={works_img_5}
                            alt="Azuki #1421"
                            hideDownload={true}
                        />
                    </div>

                    <div className="description">

                        <h3>
                            Azuki #1421
                        </h3>

                        <div className="inner-divider-half inner-divider-half-works"></div>

                        <div className="description-second">
                            @boredhefner
                        </div>

                        <div className="inner-divider-half inner-divider-half-works"></div>
                    </div>
                </div>
            </div>

            <div className="item-folio item item-wh">
                <div className="item-grid-size">

                    <div className="works-img-all fill">
                        <ModalImage
                            small={works_img_6}
                            large={works_img_6}
                            alt="OCM Karma #901"
                            hideDownload={true}
                        />
                    </div>

                    <div className="description">

                        <h3>
                            OCM Karma #901
                        </h3>

                        <div className="inner-divider-half inner-divider-half-works"></div>

                        <div className="description-second">
                            @SoldmanGachs
                        </div>

                        <div className="inner-divider-half inner-divider-half-works"></div>
                    </div>
                </div>
            </div>

            <div className="item-folio item item-wh">
                <div className="item-grid-size">

                    <div className="works-img-all fill">
                        <ModalImage
                            small={works_img_7}
                            large={works_img_7}
                            alt="Azuki #7994"
                            hideDownload={true}
                        />
                    </div>

                    <div className="description">

                        <h3>
                            Azuki #7994
                        </h3>

                        <div className="inner-divider-half inner-divider-half-works"></div>

                        <div className="description-second">
                            @boredhefner
                        </div>

                        <div className="inner-divider-half inner-divider-half-works"></div>
                    </div>
                </div>
            </div>

            <div className="item-folio item item-wh">
                <div className="item-grid-size">

                    <div className="works-img-all fill">
                        <ModalImage
                            small={works_img_8}
                            large={works_img_8}
                            alt="Tabz #998"
                            hideDownload={true}
                        />
                    </div>

                    <div className="description">

                        <h3>
                            Tabz #998
                        </h3>

                        <div className="inner-divider-half inner-divider-half-works"></div>

                        <div className="description-second">
                            @alienqueen
                        </div>

                        <div className="inner-divider-half inner-divider-half-works"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>;
}

function UpperPage() {
    return <div className="upper-page" id="home">
        <ImageSwiper/>
    </div>;
}


export default function LPHomePage() {
    const { pushPageView } = useDatalayerPushEvent();

    useEffect(() => {
        pushPageView();
    });

    return (
        <>
            <Helmet>
                <title>NFTCanvases.com | Museum Quality NFT Prints With NFC And Blockchain Authentication</title>
            </Helmet>
            <UpperPage/>
            <AboutSection/>
            <div className="inner-divider-large"></div>
            <FactsSection/>
            <ServicesSection/>
            <WorksSection/>
            <div className="inner-divider"></div>
            <WorksImages/>
            <ContactSection/>
            <Link className="page-scroll" to="/">
                <div className="to-top-arrow">
                    <span className="ion-ios-arrow-up"></span>
                </div>
            </Link>
        </>
    );
}