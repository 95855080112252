import * as React from 'react';
import {useAccount} from "wagmi";
import ProductGrid from "./product_grid";
import {redirect, useNavigate} from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {useDatalayerPushEvent} from "../hooks/analytics";
import {useEffect} from "react";


export default function HomePage() {
    const { isConnected } = useAccount();
    const navigate = useNavigate();
    const { pushPageView } = useDatalayerPushEvent();
    if (!isConnected) {
        navigate('/');
    }
    useEffect(() => {
        pushPageView();
    });
    return <>
        <Helmet>
            <title>NFTCanvases.com | Shop</title>
        </Helmet>
        <ProductGrid />
    </>
}