import sh1 from "../img/sh1.png";
import * as React from "react";

export function ViewPageBottom() {
    return <div className="container container-fullwidth">
        <div className="single-product-tab ver2">
            <div className="tab-content">
                <div id="desc" className="tab-pane fade in active">
                    <div className="tab-variable">
                        <div className="col-xs-12 col-sm-6 col-md-6 column-1">
                            <div className="product-images">
                                <img src="https://canvasesnft.com/img/background/thumbnail-slider-bg-1.jpg" alt="photo"
                                     className="img-reponsive"/>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-6 column-2">
                            <div className="product-info-ver2 center text-center">
                                <h3 className="product-title space-pm ver2 space-pm swith">Museum Quality Canvas</h3>
                                <p className="product-desc center">Each print is made to order, using the highest
                                    quality canvas and ink. We then stretch your canvas over a 1.5" deep, hand-built
                                    wooden frame built to size for your order.</p>
                            </div>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
            <div className="clearfix">&nbsp;</div>


            <div className="tab-content">
                <div id="desc" className="tab-pane fade in active">
                    <div className="tab-variable">
                        <div className="col-xs-12 col-sm-6 col-md-6 column-1">
                            <div className="product-images">
                                <img src="https://canvasesnft.com/img/background/thumbnail-slider-bg-2.jpg" alt="photo"
                                     className="img-reponsive"/>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-6 column-2">
                            <div className="product-info-ver2 center text-center">
                                <h3 className="product-title space-pm ver2 space-pm swith">Blockchain + NFC
                                    Authentication</h3>
                                <p className="product-desc center">All prints include a certificate of authenticity
                                    which is stored on the blockchain, containing details about the print and the owner
                                    at time of purchase. The COA can be viewed by passing your smartphone over the print
                                    with no camera or app download needed.</p>
                            </div>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
            <div className="clearfix">&nbsp;</div>
            <div className="tab-content">
                <div id="desc" className="tab-pane fade in active">
                    <div className="tab-variable">
                        <div className="col-xs-12 col-sm-6 col-md-6 column-1">
                            <div className="product-images">
                                <img src={sh1} alt="photo" className="img-reponsive"/>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-6 column-2">
                            <div className="product-info-ver2 center text-center">
                                <h3 className="product-title space-pm ver2 space-pm swith">x SAINT HENRI</h3>
                                <p className="product-desc center">Our collaboration with the world renowned artist <a
                                    href="https://www.houseofsainthenri.com/">SAINT HENRI</a> allows us to offer bespoke options
                                    like gold/silver leafing, diamond dusting, hand embellishing, custom framing, LED
                                    backlighting, and more to each print.</p>
                            </div>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
            <div className="clearfix">&nbsp;</div>
        </div>
    </div>;
}