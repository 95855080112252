import * as React from 'react';
import { Link } from 'react-router-dom';


export default function Footer() {
    return (<>
            <div className="inner-divider-large"></div>
            <div className="copyright">
                NFTCanvases.com © 2023 All Rights Reserved.
                <br />
                <Link className="link-underline-light" to="/privacy" target="_blank">Privacy Policy</Link> |
                <Link className="link-underline-light" to="/terms" target="_blank">Terms &amp; Conditions</Link>
            </div>
            <div className="inner-divider-large"></div>
        </>
    );
}