import * as React from 'react';
import {Link} from 'react-router-dom';
import {NFTInfo} from "../types";


import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye} from "@fortawesome/free-solid-svg-icons";
import {InfinitySpin} from "react-loader-spinner";
import {ChangeEvent} from "react";

export default function GridNFT(nft_info: NFTInfo) {
    const [isLoading, setIsLoading] = React.useState<boolean>(true);

    let imageUrl = nft_info.normalized_metadata.image;
    if (imageUrl.startsWith("ipfs://")) {
        imageUrl = `${process.env.REACT_APP_TOOLS_API_HOST}/nfts/ipfs?${new URLSearchParams({ipfsUrl: imageUrl}).toString()}`
    }

    function productClickHandler() {
        // @ts-ignore
        window.dataLayer = window.dataLayer || [];
        let selectItemData = {
            event: 'select_item',
            item_list_id: nft_info.token_address,
            item_list_name: nft_info.name,
            // @ts-ignore
            items: [{
                item_id: `${nft_info.token_address}_${nft_info.token_id}`,
                item_name: `${nft_info.name} #${nft_info.token_id}`,
                item_brand: nft_info.name,
            }]
        };
        console.debug(selectItemData);
        // @ts-ignore
        window.dataLayer.push(selectItemData);
    }

    return (
        <div className="col-xs-6 col-sm-4 col-md-4 col-lg-3 product-itoh em">
            <div className="product-img">
                <Link
                    to={`/shop/view/${nft_info.token_address}/${nft_info.token_id}`}
                    className="hover-images effect"
                    onClick={productClickHandler}
                >
                    <div style={{display: "flex", alignItems: "center"}}>
                        <img src={imageUrl} alt={nft_info.name} className={"img-responsive"}
                             style={{display: isLoading ? "none" : "block"}}
                             onLoad={event => {
                                 setIsLoading(false);
                             }}/>
                        <div style={{display: isLoading ? "block" : "none", alignItems: "center", width: "100%"}}>
                            <InfinitySpin
                                width='200'
                                color="#FFFFFF"
                            />
                        </div>
                    </div>
                </Link>
                {isLoading ? <></> :
                    <div className="product-info overlay-img box-center">
                        <h3 className="product-title">
                            <Link
                                to={`/shop/view/${nft_info.token_address}/${nft_info.token_id}`}
                                onClick={productClickHandler}
                            >{nft_info.name} #{nft_info.token_id}</Link>
                        </h3>
                        <Link to={`/shop/view/${nft_info.token_address}/${nft_info.token_id}`}
                              className="btn-quickview"
                              onClick={productClickHandler}
                        ><FontAwesomeIcon icon={faEye}/></Link>
                        <h3 className="product-title">
                            <Link
                                to={`/shop/view/${nft_info.token_address}/${nft_info.token_id}`}
                                onClick={productClickHandler}
                            >Select Print</Link>
                        </h3>
                    </div>
                }
            </div>
        </div>
    );
}