import {Link} from "react-router-dom";
import * as React from "react";

export default function ContactSection() {
    return <section id="contact">

        <div className="inner-divider-large"></div>

        <div className="container sections">

            <div className="dots">
                <div className="the-dots"></div>
            </div>
            <div className="dots-reverse">
                <div className="the-dots"></div>
            </div>

            <div className="row">

                <div className="col-lg-12">

                    <h2 className="section-heading section-heading-all section-heading-light">
                        <span>4.</span> Contact
                    </h2>

                    <div className="inner-divider-large"></div>

                    <div className="inner-divider-half"></div>

                    <div className="post-email-title">
                        <a href="https://twitter.com/canvasesnft" target="_blank">Twitter</a><br/>
                        <a href="https://instagram.com/nft.canvases" target="_blank">Instagram</a><br/>
                        <a href="https://tiktok.com/@nftcanvases" target="_blank">TikTok</a>
                    </div>

                </div>

            </div>


            <div className="inner-divider-large"></div>
            <div className="inner-divider-large"></div>
        </div>
    </section>;
}