import * as React from 'react';
import GridNFT from "./grid_nft";
import "../css/product_img.css";
import {ChangeEvent, useEffect} from "react";
import {useAccount} from "wagmi";
import {NFTCollection, NFTInfo} from "../types";
import {MainHeroImage} from "./main_hero_image";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSliders} from "@fortawesome/free-solid-svg-icons";
// @ts-ignore
import FormControl from "react-bootstrap/es/FormControl";
// @ts-ignore
import Row from "react-bootstrap/es/Row";
// @ts-ignore
import Grid from "react-bootstrap/es/Grid";
// @ts-ignore
import Col from "react-bootstrap/es/Col";
import {useSearchParams} from "react-router-dom";
import {useDatalayerPushEvent} from "../hooks/analytics";


export default function ProductGrid() {

    const [queryParameters] = useSearchParams();
    const [initialLoad, setInitialLoad] = React.useState<boolean>(true);
    const [collectionsLoaded, setCollectionsLoaded] = React.useState<boolean>(false);
    const [collections, setCollections] = React.useState<Array<NFTCollection>>([]);
    const [collectionNFTs, setCollectionNFTs] = React.useState<Array<NFTInfo>>([]);

    const { address, isConnected } = useAccount();
    const { pushPageView } = useDatalayerPushEvent();

    const OVERRIDE_WALLETS = [
        "0x404537d01ffa40ea8fC6dEe157acfFAC2ADf3C60",
        "0xb44Df61BA4B5Dd10869F75232667c9a0F3691211",
        "0xFe3C1a5138F885cC91D2BB3162ECf85AAc95D2C9",
    ];

    useEffect(() => {
        const fetchCollections = async () => {
            let targetWallet = address;
            let overrideWallet = queryParameters.get('override_wallet');
            if (overrideWallet !== null && OVERRIDE_WALLETS.find(x => x === address)){
                // @ts-ignore
                targetWallet = overrideWallet;
            } else if (overrideWallet !== null && !OVERRIDE_WALLETS.find(x => x === address)) {
                console.debug("This wallet is not authorized for override testing.");
            }
            let data = await fetch(`${process.env.REACT_APP_TOOLS_API_HOST}/nfts/ownedCollections/${targetWallet}`);
            let jsonResponse = await data.json();
            console.debug(jsonResponse.result);
            // @ts-ignore
            jsonResponse.result.sort((a, b) => a.name.localeCompare(b.name));
            setCollections(jsonResponse.result);
            setCollectionsLoaded(true);
        }
        fetchCollections().catch(console.error);
    }, []);

    function collectionChange(event: ChangeEvent<HTMLSelectElement>) {
        let colAddress = event.target.value;
        setCollectionNFTs([]);
        if (colAddress.length > 0){
            setInitialLoad(false);
            const loadNFTs = async () => {
                let targetWallet = address;
                let overrideWallet = queryParameters.get('override_wallet');
                if (overrideWallet !== null && OVERRIDE_WALLETS.find(x => x === address)){
                    // @ts-ignore
                    targetWallet = overrideWallet;
                } else if (overrideWallet !== null && !OVERRIDE_WALLETS.find(x => x === address)) {
                    console.debug("This wallet is not authorized for override testing.");
                }
                let data = await fetch(`${process.env.REACT_APP_TOOLS_API_HOST}/nfts/byOwner/${targetWallet}?${new URLSearchParams({token_addresses: colAddress})}`);
                let jsonResponse = await data.json();
                console.log(jsonResponse.result);
                setCollectionNFTs(jsonResponse.result);
                let loadedCollectionName = event.target[event.target.selectedIndex].textContent;
                console.debug(loadedCollectionName);
                // @ts-ignore
                window.dataLayer = window.dataLayer || [];
                // Todo eventually one of these after testing both collection_selected and view_item_list.
                //  view_item_list is recommended so lets see if it works.
                // @ts-ignore
                window.dataLayer.push({
                    event: "collection_selected",
                    collection_address: colAddress,
                    collection_name: loadedCollectionName,
                });
                let viewItemListData = {
                    event: "view_item_list",
                    item_list_id: colAddress,
                    item_list_name: loadedCollectionName,
                    // @ts-ignore
                    items: jsonResponse.result.map(x => {
                        return {
                            item_id: `${x.token_address}_${x.token_id}`,
                            item_name: `${x.name} #${x.token_id}`,
                            item_brand: x.name,
                        }
                    })
                };
                console.debug(viewItemListData);
                // @ts-ignore
                window.dataLayer.push(viewItemListData);
            }
            loadNFTs().catch(console.error);
        }
    }

    function NFTGrid() {
        if (initialLoad) {
            return <span className={"redtext"}>You must select a collection first.</span>
        }else if (collectionNFTs.length === 0){
            return <span className={"redtext"}>This collection has no valid NFTs.</span>
        }else if (collectionNFTs.filter(x => x.normalized_metadata.image === null).length > 0) {
            return <span className={"redtext"}>This collection is not supported.</span>
        } else {
            return (
                <>
                    {collectionNFTs.map(x => {
                        return <GridNFT key={x.token_address + "_" + x.token_id}{...x} />
                    })}
                </>
            );
        }
    }
    return (
        <>
            <MainHeroImage />
            <Grid>
                <Row className={"aligned-row"}>
                    <Col xs={12} md={4} className={"custom-wrap-filter-box"}>
                        <span className="total-count" style={{paddingTop: "50%"}}>{
                            initialLoad ?
                                <span className={"redtext"}>You must select a collection first.</span>:
                                <span className={"success"}>Showing {collectionNFTs.length} tokens</span>
                        }</span>
                    </Col>
                    <Col xs={12} md={4} className={"custom-wrap-filter-box"}>
                        <span className="filter-title"><FontAwesomeIcon icon={faSliders} /></span>
                    </Col>
                    <Col xs={12} md={4} className={"custom-wrap-filter-box-end"}>
                        <FormControl disabled={!collectionsLoaded} componentClass="select" placeholder="Select Collection" style={{marginLeft: "10px", marginRight: "10px", maxWidth: "95%"}} onChange={collectionChange}>
                            <option value="">{!collectionsLoaded ? "Collections Are Loading..." : "Select Collection"}</option>
                            {collections.map(x => {
                                return <option key={x.token_address} value={x.token_address}>{x.name}</option>
                            })}
                        </FormControl>
                    </Col>
                </Row>
            </Grid>
            <div className="product-grid modern-grid" style={{paddingTop: "10px"}}>
                <div className="container container-42">
                    <div className="tab-content">
                        <div id="all" className="tab-pane fade in active">
                            <div className="row">
                                <NFTGrid />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}