export const useDatalayerPushEvent = () => {

    const pushPageView = () => {
        setTimeout(() => {
            let infoTitle = document.title;
            let pageInfo = {
                page_location: document.URL,
                page_title: infoTitle
            };
            // @ts-ignore
            window.dataLayer = window.dataLayer || [];
            let dlData = {
                event: 'page_view',
                ...pageInfo
            };
            // @ts-ignore
            window.dataLayer.push(dlData);
        });
    }

    return { pushPageView }
}