import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {router} from "./router";

import {RouterProvider} from "react-router-dom";

import '@rainbow-me/rainbowkit/styles.css';
import {getDefaultWallets, RainbowKitProvider, darkTheme} from '@rainbow-me/rainbowkit';
import {configureChains, createConfig, WagmiConfig} from 'wagmi';
import {arbitrum, base, mainnet, optimism, polygon, zora,} from 'wagmi/chains';
import {alchemyProvider} from 'wagmi/providers/alchemy';
import {publicProvider} from 'wagmi/providers/public';
import {CartProvider} from "react-use-cart";
import { Helmet, HelmetProvider } from 'react-helmet-async';


// @ts-ignore
import TagManager from 'react-gtm-module';

const {chains, publicClient} = configureChains(
    [mainnet, polygon, optimism, arbitrum, base, zora],
    [
        alchemyProvider({apiKey: process.env.REACT_APP_ALCHEMY_ID!}),
        publicProvider()
    ]
);

const {connectors} = getDefaultWallets({
    appName: 'My RainbowKit App',
    projectId: process.env.REACT_APP_WC_PROJECT_ID!,
    chains
});

const wagmiConfig = createConfig({
    autoConnect: true,
    connectors,
    publicClient
})

const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID,
    auth: process.env.REACT_APP_GTM_AUTH,
    preview: process.env.REACT_APP_GTM_PREVIEW
}

TagManager.initialize(tagManagerArgs);


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <HelmetProvider>
        <CartProvider>
            <WagmiConfig config={wagmiConfig}>
                <RainbowKitProvider chains={chains} theme={darkTheme({overlayBlur: 'small'})}>
                    <RouterProvider router={router}/>
                </RainbowKitProvider>
            </WagmiConfig>
        </CartProvider>
    </HelmetProvider>
);

reportWebVitals();
