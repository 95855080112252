import * as React from 'react';
import {useAccount} from "wagmi";
import {ViewPageBottom} from "./view_page_bottom";
import {NFTCartInfo, NFTInfo, PriceMap} from "../types";
import {Circles} from "react-loader-spinner";
import {ChangeEvent, useEffect} from "react";
import {useParams} from "react-router-dom";
import {useCart} from "react-use-cart";
import NFTImg from "./nftImg";
import {Helmet} from "react-helmet-async";
import {useDatalayerPushEvent} from "../hooks/analytics";

// todo Pull these from API when viewing so we can eventually show different sizes based on dimensions.
const SIZE_PRICES: Array<PriceMap> = [
    {displayName: "12x12", itemPrice: 199},
    {displayName: "18x18", itemPrice: 249},
    {displayName: "24x24", itemPrice: 299},
    {displayName: "30x30", itemPrice: 349},
]

const GLOSS_PRICES: Array<PriceMap> = [
    {displayName: "No", itemPrice: 0},
    {displayName: "Yes", itemPrice: 109.95},
]

export default function ViewPage() {
    const { addItem } = useCart();

    const { isConnected } = useAccount();
    const [nftInfo, setNftInfo] = React.useState<NFTInfo | null>(null);
    const [selectedSize, setSelectedSize] = React.useState<PriceMap>(SIZE_PRICES[0]);
    const [selectedGloss, setSelectedGloss] = React.useState<PriceMap>(GLOSS_PRICES[0]);

    let { collectionAddress, tokenID } = useParams();
    const { pushPageView } = useDatalayerPushEvent();

    useEffect(() => {
        const fetchNFTInfo = async () => {
            let data = await fetch(`${process.env.REACT_APP_TOOLS_API_HOST}/nfts/tokenInfo/${collectionAddress}/${tokenID}`);
            let jsonResponse = await data.json();
            setNftInfo(jsonResponse);
            pushPageView();
            let viewItemData = {
                event: "view_item",
                item_list_id: jsonResponse.token_address,
                item_list_name: jsonResponse.name,
                items: [{
                    item_id: `${jsonResponse.token_address}_${jsonResponse.token_id}`,
                    item_name: `${jsonResponse.name} #${jsonResponse.token_id}`,
                    item_brand: jsonResponse.name,
                }]
            };
            // @ts-ignore
            window.dataLayer = window.dataLayer || [];
            // @ts-ignore
            window.dataLayer.push(viewItemData);
        }
        fetchNFTInfo().catch(console.error);
    }, []);

    function addToCartClick() {
        let product: NFTCartInfo = {
            id: `${nftInfo?.token_address}_${nftInfo?.token_id}_${selectedSize.displayName}_${selectedGloss.displayName}`,
            name: `${nftInfo?.name} #${nftInfo?.token_id}`,
            nftInfo: nftInfo!,
            selectedSize: selectedSize!,
            selectedGloss: selectedGloss!,
            price: selectedSize.itemPrice + selectedGloss.itemPrice,
        };
        addItem(product, 1);
        let addedToCartData = {
            event: "add_to_cart",
            item_list_id: nftInfo?.token_address,
            item_list_name: nftInfo?.name,
            currency: "USD",
            value: selectedSize.itemPrice + selectedGloss.itemPrice,
            items: [{
                item_id: `${nftInfo?.token_address}_${nftInfo?.token_id}`,
                item_name: `${nftInfo?.name} #${nftInfo?.token_id}`,
                item_brand: nftInfo?.name,
                price: selectedSize.itemPrice + selectedGloss.itemPrice,
                print_size: selectedSize!.displayName,
                print_gloss: selectedGloss!.displayName,
            }]
        };
        // @ts-ignore
        window.dataLayer = window.dataLayer || [];
        // @ts-ignore
        window.dataLayer.push(addedToCartData);
        alert("Your item was added to the cart!");
    }

    function onGlossChange(event: ChangeEvent<HTMLSelectElement>) {
        let glossPriceInfo = GLOSS_PRICES.find(x => {return x.itemPrice === parseFloat(event.target.value)});
        setSelectedGloss(glossPriceInfo!);
        let selectGlossData = {
            event: "select_content",
            content_type: "item_gloss",
            content_id: glossPriceInfo?.displayName,
        };
        // @ts-ignore
        window.dataLayer = window.dataLayer || [];
        // @ts-ignore
        window.dataLayer.push(selectGlossData);
    }

    function onSizeChange(event: ChangeEvent<HTMLSelectElement>) {
        let sizePriceInfo = SIZE_PRICES.find(x => {return x.itemPrice === parseFloat(event.target.value)});
        setSelectedSize(sizePriceInfo!);
        let selectSizeData = {
            event: "select_content",
            content_type: "item_size",
            content_id: sizePriceInfo?.displayName,
        };
        // @ts-ignore
        window.dataLayer = window.dataLayer || [];
        // @ts-ignore
        window.dataLayer.push(selectSizeData);
    }

    function AddToCartContainer() {
        if (nftInfo === null) {
            return (<Circles
                height="80"
                width="80"
                color="#4fa94d"
                ariaLabel="circles-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
            />);
        }
        return (
            <>
                <div className="single-product-detail product-bundle space-50">
                    <div className="row">
                        <div className="col-xs-12 col-sm-5 col-md-6">
                            <div className="product-images">
                                <div className="main-img js-product-slider">
                                    <NFTImg {...nftInfo} />
                                </div>
                            </div>
                            <div className="multiple-img-list-ver2 js-click-product">
                                &nbsp;
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-7 col-md-6">
                            <div className="single-product-info">
                                <div className="rating-star">
                                    &nbsp;
                                </div>
                                <h3 className="product-title space-pm"><a>{nftInfo.name} #{nftInfo.token_id}</a></h3>
                                <div className="product-price">
                                    <span>${selectedSize.itemPrice + selectedGloss.itemPrice}</span>
                                </div>
                                <p className="product-desc" style={{paddingBottom: "20px"}}>
                                    Ready to hang canvas, on a hand-made wooden frame. All prints are NFC enabled and include a
                                    certificate of authenticity on the blockchain. For a custom piece done by SAINT HENRI <a
                                    href="mailto:pk@nftcanvases.com">contact us</a>.
                                </p>
                                <div className="form-group">
                                    <label htmlFor="size" style={{color: "white"}}>Size</label>
                                    <select className="form-control" id="size" onChange={onSizeChange}>
                                        {SIZE_PRICES.map(x => {
                                            return <option
                                                key={x.displayName}
                                                value={x.itemPrice}
                                                selected={x.displayName == selectedSize.displayName}
                                            >{x.displayName} (${x.itemPrice})</option>
                                        })}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="resin" style={{color: "white"}}>Resin Coating (Gloss Finish)</label>
                                    <select className="form-control" id="resin" onChange={onGlossChange}>
                                        {GLOSS_PRICES.map(x => {
                                            return <option
                                                key={x.displayName}
                                                value={x.itemPrice}
                                                selected={x.displayName == selectedGloss.displayName}
                                            >{x.displayName} (${x.itemPrice})</option>
                                        })}
                                    </select>
                                </div>
                                <div className="action" style={{width: "100%", textAlign: "center"}}>
                                    <button className="link-ver1 add-cart" style={{width: "45%"}} onClick={addToCartClick}>Add To Cart</button>
                                    <div className="clearfix"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    if (!isConnected) {
        return (
          <>
              <Helmet>
                  <title>NFTCanvases.com | View Not Connected</title>
              </Helmet>
              <div className="inner-divider-large"></div>
              <div className="text-center">You must connect a wallet to utilize our website.</div>
          </>
        );
    } else {
        return (<>
            <Helmet>
                <title>NFTCanvases.com | Viewing Item</title>
            </Helmet>
            <div className={"inner-divider-large"}></div>
            <div className="container">
                <AddToCartContainer/>
            </div>
            <ViewPageBottom/>
        </>);
    }
}