import * as React from 'react';
import {useAccount} from "wagmi";
import ProductGrid from "./product_grid";
import {useNavigate} from "react-router-dom";
import {useDatalayerPushEvent} from "../hooks/analytics";
import {useEffect} from "react";
import {Helmet} from "react-helmet-async";


export default function PaymentComplete() {
    const { isConnected } = useAccount();
    const navigate = useNavigate();
    const { pushPageView } = useDatalayerPushEvent();
    if (!isConnected) {
        navigate('/');
    }
    useEffect(() => {
        pushPageView();
    });
    return (
      <>
          <Helmet>
              <title>NFTCanvases.com | Payment Complete</title>
          </Helmet>
          <div className={"inner-divider-large"}></div>
          <div className="text-center">
              Thank you!  Your payment is complete!
              <br />
              You will be contacted shortly.
          </div>
      </>
    );
}