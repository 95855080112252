import * as React from "react";

/* style.css is from the landing page */
import "../css/shop.css";
import "../css/style.css";
import "../css/custom.css";

// Nav Bar Logo
import {NFTCBootstrapNavbar} from "../components/common/navbar";
import Footer from "../components/footer";
import {Outlet, ScrollRestoration} from "react-router-dom";
import ScrollToAnchor from "../components/common/scroll_to_anchor";


export default function NFTCRootLayout() {

    return (
        <>
            <ScrollRestoration/>
            <ScrollToAnchor />
            <div className="border-top"></div>
            <div id={"header"}>
                <NFTCBootstrapNavbar />
            </div>
            <Outlet/>
            <Footer/>
        </>
    );
}