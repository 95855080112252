import * as React from "react";
import {Helmet} from "react-helmet-async";


export default function ErrorPage() {

    return (
        <>
            <Helmet>
                <title>NFTCanvases.com | An Error Has Occurred</title>
            </Helmet>
            An error occurred.
        </>
    );
}